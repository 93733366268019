import React from 'react';
import {
  Grid,
  Menu,
  Divider,
  Container,
  Image,
  GreySegment,
  WhiteGrid,
} from 'paperm88n-ui';
import { withTranslation } from 'react-i18next';

import footer from 'paperm88n-resources/footer.png';

export function LayoutFooterView({ t }) {
  return (
    <GreySegment inverted>
      <Divider />
      <Container>
        <Grid columns="3">
          <Grid.Row>
            <Grid.Column verticalAlign="middle">
              <Menu text vertical>
                <WhiteGrid>{t('layout.header.menu.catalog')}</WhiteGrid>
              </Menu>
            </Grid.Column>
            <Grid.Column>
              <Image src={footer} size="big" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <Divider />
    </GreySegment>
  );
}

export default withTranslation()(LayoutFooterView);
