import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const LANGUAGES = {
  ru: 'ru',
  en: 'en',
};

/**
 * @typedef {object} LocaleModel
 * @property {function():string} getLang
 * @property {function():void} setLang
 * @property {boolean} loading
 */

/** @returns {LocaleModel} */
export default function useLocale() {
  const [lang, setLang] = useState(LANGUAGES.ru);
  const { i18n, ready } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  return {
    setLang,
    loading: !ready,
    getLang() {
      return lang;
    },
  };
}
