import React, { createContext, useContext } from 'react';

import useSidebar from '../services/useSidebar';
import useContent from '../services/useContent';
import useLists from '../services/useLists';
import useLocale from '../services/useLocale';

/**
 * @typedef {import("../services/useContent").ContentModel} ContentModel
 * @typedef {import("../services/useLocale").LocaleModel} LocaleModel
 */

/**
 * @typedef {object} AppContext
 * @property {ContentModel} app
 * @property {object} cart
 * @property {LocaleModel} locale
 * @property {object} wishlist
 */

const AppContext = createContext();

export function AppContextProvider({ children }) {
  const context = {
    sidebar: useSidebar(),
    app: useContent('app'),
    cart: useLists({ name: 'cart' }),
    locale: useLocale(),
    wishlist: useLists({ name: 'wishlist' }),
  };
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
}

/** @returns {AppContext} */
export const getAppContext = (namespace = null) => {
  const context = useContext(AppContext);
  if (namespace && context[namespace]) {
    return context[namespace];
  }
  return context;
};

/** @returns {ContentModel} */
export const getApp = () => getAppContext('app');
export const getCart = () => getAppContext('cart');
/** @returns {LocaleModel} */
export const getLocale = () => getAppContext('locale');
export const getWishlist = () => getAppContext('wishlist');
