import { useTranslation } from 'react-i18next';

function typeAndCategory({ type, category }) {
  return {
    type,
    category,
  };
}

export function getMenuDataFromInventory(inventory = []) {
  return inventory.length > 0
    ? inventory.map(typeAndCategory).reduce((acc, obj) => {
      const key = obj.type;
      if (!acc[key]) {
        acc[key] = [];
      }
      if (!acc[key].includes(obj.category)) {
        acc[key].push(obj.category);
      }
      return acc;
    }, {})
    : {};
}

export default function useCatalogMenu({ data }) {
  const menu = getMenuDataFromInventory(data);
  const { t } = useTranslation();
  return {
    menu,
    t,
  };
}
