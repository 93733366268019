import styled from 'styled-components';
import { Dimmer } from 'semantic-ui-react';

const DimmerStyled = styled(Dimmer)`
  &&& {
    background-color: white !important;
  }
`;

export default DimmerStyled;
