import { useState } from 'react';
import { fromJS } from 'immutable';

function addItem(state, name, setState) {
  return (item) => {
    const elIndex = state
      .getIn([name, 'items'])
      .findIndex(($) => $.toJS().id === item.id);
    let newState;
    if (elIndex > -1) {
      newState = state.updateIn(
        [name, 'items', elIndex, 'qtd'],
        ($qtd) => $qtd + 1,
      );
    } else {
      newState = state.updateIn([name, 'items'], ($) => $.push(fromJS({
        ...item,
        qtd: 1,
      })));
    }

    setState(newState);
  };
}

function removeItem(state, name, setState) {
  return (itemId) => {
    const elIndex = state
      .getIn([name, 'items'])
      .findIndex(($) => $.toJS().id === itemId);
    const newState = state.deleteIn([name, 'items', elIndex]);
    setState(newState);
  };
}

function updateItemQtd(state, name, setState) {
  return (itemId, qtd) => {
    const elIndex = state
      .getIn([name, 'items'])
      .findIndex(($) => $.toJS().id === itemId);
    let newState;
    if (elIndex > -1) {
      newState = state.updateIn([name, 'items', elIndex, 'qtd'], () => qtd);
    }
    setState(newState);
  };
}

export default function useLists(options) {
  const { name } = options;
  let storedCart = { [name]: { items: [] } };

  try {
    storedCart = JSON.parse(window.localStorage.getItem(name)) || storedCart;
  } catch (e) {
    window.localStorage.setItem(name, JSON.stringify(storedCart));
  }

  const [state, setState] = useState(fromJS(storedCart));

  const setStateWithPersistance = (data) => {
    setState(data);
    window.localStorage.setItem(name, JSON.stringify(data));
  };

  return {
    getItems: () => state.toJS()[name].items,
    addItem: addItem(state, name, setStateWithPersistance),
    removeItem: removeItem(state, name, setStateWithPersistance),
    updateItemQtd: updateItemQtd(state, name, setStateWithPersistance),
  };
}
