import styled from "styled-components";
import { Button } from "semantic-ui-react";

export const BuyButton = styled(Button)`
  &&& {
    background: transparent;
    border: solid 1px;
    &[focus="true"] {
      background: #faf5a3;
    }
  }
`;
