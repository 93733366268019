import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Icon,
  Menu,
  CatalogMenuIcons,
  Segment,
  Grid,
  Responsive,
  Image,
  Label,
  MainMenu,
  Container,
} from 'paperm88n-ui';

import { getAppContext } from '../AppContext';

import LayoutLocaleMenu from './LayoutLocaleMenu';

export function LayoutHeaderView({
  sidebar, cartItems, wishlistItems, logo, t,
}) {
  return (
    <>
      <Responsive maxWidth={768}>
        <Container>
          <Menu borderless fixed="top">
            <Menu.Item
              as={Link}
              to="/"
            >
              <Responsive
                maxWidth={374}
                as={Image}
                src={logo}
                width="100"
              />
              <Responsive
                size="small"
                minWidth={375}
                as={Image}
                src={logo}
              />
            </Menu.Item>
            <Menu.Menu widths={3} position="right">
              <Menu.Item as={Link} to="/wishlist" className="mobile hidden grid">
                <ListIcon iconName="heart outline" items={wishlistItems} />
              </Menu.Item>
              <Menu.Item as={Link} to="/cart">
                <ListIcon iconName="shopping cart" items={cartItems} />
              </Menu.Item>
              <Menu.Item
                name="editorials"
                onClick={() => !sidebar.isVisible && sidebar.show()}
              >
                <Icon size="large" name="sidebar" />
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </Container>
      </Responsive>
      <Responsive minWidth={769}>
        <Segment basic>
          <Grid verticalAlign="middle" stackable columns={3}>
            <Grid.Column>
              <Menu.Item as={Link} to="/">
                <Responsive
                  maxWidth={Responsive.onlyLargeScreen.minWidth}
                  as={Image}
                  src={logo}
                  centered
                />
                <Responsive
                  style={{
                    width: '250px',
                  }}
                  minWidth={Responsive.onlyLargeScreen.minWidth}
                  as={Image}
                  src={logo}
                />
              </Menu.Item>
            </Grid.Column>
            <Grid.Column floated="right" verticalAlign="middle">
              <LayoutLocaleMenu />
              <CatalogMenuIcons size="massive" floated="right" secondary>
                <Menu.Item as={Link} to="/wishlist">
                  <ListIcon iconName="heart outline" items={wishlistItems} />
                </Menu.Item>
                <Menu.Item as={Link} to="/cart">
                  <ListIcon iconName="shopping cart" items={cartItems} />
                </Menu.Item>
              </CatalogMenuIcons>
            </Grid.Column>
          </Grid>
          <MainMenu>
            <MainMenu.Item>
              <Link to="/catalog">{t('layout.header.menu.main.shop')}</Link>
            </MainMenu.Item>
            <MainMenu.Item>
              <Link to="/photos">
                {t('layout.header.menu.main.photos')}
              </Link>
            </MainMenu.Item>
            <MainMenu.Item>
              <Link to="/where-to-buy">{t('layout.header.menu.main.where.to.buy')}</Link>
            </MainMenu.Item>
            <MainMenu.Item>
              <Link to="/delivery-and-payment">{t('layout.header.menu.main.delivery.and.payment')}</Link>
            </MainMenu.Item>
            <MainMenu.Item>
              <Link to="/about">{t('layout.header.menu.main.about')}</Link>
            </MainMenu.Item>
          </MainMenu>
        </Segment>
      </Responsive>
    </>


  );
}


function ListIcon(props) {
  const { iconName, items } = props;
  return (
    <>
      <Responsive
        maxWidth={374}
      >
        <Icon.Group size="large">
          <Icon name={iconName} color="grey" />
          {items.length > 0 && (
          <Label size="mini" color="red" floating>
            {items.reduce((acc, cur) => acc + cur.qtd, 0)}
          </Label>
          )}
        </Icon.Group>
      </Responsive>
      <Responsive
        minWidth={375}
      >
        <Icon.Group size="big">
          <Icon name={iconName} color="grey" />
          {items.length > 0 && (
          <Label color="red" floating>
            {items.reduce((acc, cur) => acc + cur.qtd, 0)}
          </Label>
          )}
        </Icon.Group>
      </Responsive>
    </>

  );
}

export default function LayoutHeader() {
  const {
    app, cart, wishlist, sidebar,
  } = getAppContext();
  const { t } = useTranslation();
  return (
    <LayoutHeaderView
      sidebar={sidebar}
      cartItems={cart.getItems()}
      wishlistItems={wishlist.getItems()}
      logo={app.getLogo()}
      t={t}
    />
  );
}
