import { useState, useEffect } from 'react';

import fetch from './fetch';

function contentDataAccess(contentId) {
  return fetch(`/api/content/${contentId}`).then((res) => res.json());
}

/**
 * @typedef {object} SectionItemModel
 * @property {function():string} getLegend
 * @property {function():string} getImg
 * @property {function():string} getUrl
 */

/**
 * @returns {SectionItemModel}
 */
export function sectionItemModel({ legend, img, url }) {
  return Object.freeze({
    getLegend() {
      return legend;
    },
    getImg() {
      return img;
    },
    getUrl() {
      return url;
    },
  });
}

/**
 * @typedef {object} SectionModel
 * @property {function():string} getName
 * @property {function():string} getUrl
 * @property {function():SectionItemModel[]} getItems
 */

/**
 * @returns {SectionModel}
 */
export function sectionModel([key = '', items = []]) {
  const [section, url] = key.split(',');
  return Object.freeze({
    getName() {
      return section;
    },
    getUrl() {
      return url;
    },
    getItems() {
      return items.map(sectionItemModel);
    },
  });
}


/**
 *
 * @typedef {object} ContentModel
 * @property {function():string} getLogo
 * @property {function():string} getFooter
 * @property {function():string} getDefaultSection
 * @property {function():string[]} getSliders
 * @property {function():SectionModel[]} getSections
 * @property {boolean} loading
 */

/**
 *
 * @returns {ContentModel}
 */
export function contentModel({ general = {}, sliders = [], sections = [] }) {
  const {
    logo, footer, defaultSection,
  } = general;

  return Object.freeze({
    getLogo() {
      return logo;
    },
    getFooter() {
      return footer;
    },
    getDefaultSection() {
      return defaultSection;
    },
    getSliders() {
      return sliders;
    },
    getSections() {
      return Object.entries(sections).map(sectionModel);
    },
  });
}

function contentStateMutation(updater, contentId) {
  return () => {
    updater({
      loading: true,
      ...contentModel({}),
    });
    contentDataAccess(contentId).then((result) => {
      updater({ ...contentModel(result), loading: false });
    });
  };
}

/** @returns {ContentModel} */
export default function useContent(contentId) {
  const [state, setState] = useState({
    loading: false,
    ...contentModel({}),
  });

  useEffect(contentStateMutation(setState, contentId), []);

  return state;
}
