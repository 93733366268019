import { useState } from 'react';


export default function useSidebar() {
  const [state, setState] = useState({
    visible: false,
  });


  return {
    isVisible: state.visible,
    toggle: () => setState({ visible: !state.visible }),
    hide: () => setState({ visible: false }),
    show: () => setState({ visible: true }),
  };
}
