import React, { useState } from 'react';

import { Image, Transition } from 'semantic-ui-react';
import splashScreenImage from 'paperm88n-resources/splashscreen.svg';

import DimmerStyled from './DimmerStyled';


export function SplashScreen({ visible, loaderId = 'loader' }) {
  const [active, setActive] = useState(true);

  const removeLoader = () => {
    const loader = document.getElementById(loaderId);
    if (loader) loader.style.visibility = 'hidden';
  };

  return (
    <Transition
      visible={visible}
      animation="fade"
      duration={500}
      onHide={() => setActive(false)}
      onStart={() => {
        removeLoader();
      }}
    >
      <DimmerStyled inverted active={active} page>
        <Image src={splashScreenImage} />
      </DimmerStyled>
    </Transition>
  );
}

export default SplashScreen;
