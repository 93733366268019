import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

const HomePage = lazy(() => import('./Home/HomePage'));
const CatalogPage = lazy(() => import('./Catalog/CatalogPage'));
const CartPage = lazy(() => import('./Cart/CartPage'));
const PhotosPage = lazy(() => import('./Photos/PhotosPage'));
const WhereToBuy = lazy(() => import('./WhereToBuy/WhereToBuy'));
const NotFoundPage = lazy(() => import('./NotFound/NotFound'));

export default function Routes() {
  return (
    <Suspense fallback={<h1>Loading</h1>}>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route
          path="/catalog/:type?/:category?"
          component={CatalogPage}
        />
        <Route path="/cart" component={CartPage} />
        <Route path="/photos" component={PhotosPage} />
        <Route path="/where-to-buy" component={WhereToBuy} />
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
}
