import styled from 'styled-components';
import { Card } from 'semantic-ui-react';

const HomeSectionCardGroup = styled(Card.Group)`
    &&&& {
        .card:not(:last-child) {
            @media (max-width: 768px) {
                margin-bottom: 40px;
            }
        }        
    }
`;

export default HomeSectionCardGroup;
