import { useState, useEffect } from 'react';

import fetch from './fetch';

function inventoryDataAccess() {
  return fetch('/api/inventory').then((res) => res.json());
}

function inventoryStateMutation(updater) {
  return () => {
    updater({
      loading: true,
    });
    inventoryDataAccess().then((res) => {
      updater({ inventory: res, loading: false });
    });
  };
}

export default function useInventory() {
  const [state, setState] = useState({ inventory: [], loading: false });

  useEffect(inventoryStateMutation(setState), []);

  return state;
}
