import {
  useState, useLayoutEffect, useRef, useEffect,
} from 'react';

export function useSizeProportion(proportion, widthOffset = 0, heightOffset = 0) {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      const w = window.outerWidth + widthOffset;
      const h = Math.ceil(window.outerWidth / proportion) + heightOffset;
      setSize([w, h]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}


export default function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}


export function useAdjustImageSizeWithContainer(defaultWidth = 0) {
  const [width, setWidth] = useState(defaultWidth);
  const [windowWidth] = useWindowSize();

  const ref = useRef(null);

  useEffect(() => {
    const { offsetWidth } = (ref.current || {}).offsetParent || {};
    setWidth(offsetWidth > 0 ? offsetWidth : defaultWidth);
  }, [ref.current, windowWidth]);

  return { width, ref };
}
