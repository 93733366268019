/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import styled from 'styled-components';
import { Menu, Responsive } from 'semantic-ui-react';

const CenterContainedGrid = ({ children, ...props }) => (
  <Menu widths={5} text stackable {...props}>
    {children}
  </Menu>
);

const MainMenu = styled(CenterContainedGrid)`
  &&&&& {
    width: 55% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  @media(min-width: ${Responsive.onlyLargeScreen.minWidth}px) {
    :before {
        content: ' ';
        width: 98%;
        height: 1px;
        position: absolute;
        background-color: rgb(136, 136, 136);
        margin: auto;
    }
  }
  
`;

const TextCenterGridColumn = ({ children, to, ...props }) => (
  <Menu.Item {...props}>{children}</Menu.Item>
);

MainMenu.Item = styled(TextCenterGridColumn)`
  &&&&& {
    padding-top: 20px;
    margin: auto;
    font-size: 16px;
    text-transform: uppercase;
    a {
      color: rgb(136, 136, 136);
    }
  }
`;

export default MainMenu;
