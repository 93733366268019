import styled from 'styled-components';
import { Menu, Responsive } from 'semantic-ui-react';

export const CatalogMenuIcons = styled(Menu)`
    &&&&&{
        margin: 10px 0px 0px 0px;

        @media(max-width: ${Responsive.onlyMobile.maxWidth}px) {
            justify-content: center;
            float: none;
        }
    } 
`;

export const CatalogMenuLeft = styled(Menu)`
  margin-left: 40px !important;
`;

export const CatalogMenuHeader = styled(Menu.Header)`
  color: #ff9293;
  font-size: 20px !important;
`;
