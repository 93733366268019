import { Menu } from 'semantic-ui-react';
import styled from 'styled-components';

const SidebarMenu = styled(Menu)`
    &&&&& {
        height: ${(props) => props.height}px !important;
        overflow-y: scroll !important;
    }
`;

export default SidebarMenu;
