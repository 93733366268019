import React from 'react';
import { HashRouter as Router } from 'react-router-dom';

import { AppContextProvider } from './AppContext';
import Layout from './Layout/Layout';
import Routes from './Routes';

export default function App() {
  return (
    <AppContextProvider>
      <Router>
        <Layout>
          <Routes />
        </Layout>
      </Router>
    </AppContextProvider>
  );
}
