import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import numeral from 'numeral';

const { __hash__: hash } = window;

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `/api/locales/{{lng}}/translations.json?hash=${hash}`,
      addPath: '/api/locales/{{lng}}/addKey',
    },
    keySeparator: true,
    saveMissing: false,
    fallbackLng: 'ru',
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
      wait: true,
    },
  });


numeral.register('locale', 'ru', {
  languageTag: 'ru-RU',
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'тыс.',
    million: 'млн',
    billion: 'b',
    trillion: 't',
  },
  ordinal() {
    // not ideal, but since in Russian it can taken on
    // different forms (masculine, feminine, neuter)
    // this is all we can do
    return '.';
  },
  currency: {
    symbol: 'руб.',
    position: 'postfix',
    code: 'RUB',
  },
  currencyFormat: {
    thousandSeparated: true,
    totalLength: 4,
    spaceSeparated: true,
    average: true,
  },
  formats: {
    fourDigits: {
      totalLength: 4,
      spaceSeparated: true,
      average: true,
    },
    fullWithTwoDecimals: {
      output: 'currency',
      mantissa: 2,
      spaceSeparated: true,
      thousandSeparated: true,
    },
    fullWithTwoDecimalsNoCurrency: {
      mantissa: 2,
      thousandSeparated: true,
    },
    fullWithNoDecimals: {
      output: 'currency',
      spaceSeparated: true,
      thousandSeparated: true,
      mantissa: 0,
    },
  },
});

numeral.locale('ru');
