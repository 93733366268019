import React, { useState } from 'react';
import {
  Responsive, Segment, Dimmer, SplashScreen, Sidebar, SidebarMenu, Menu, Flag, useScrollPosition,
} from 'paperm88n-ui';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../../constants';

import { getAppContext } from '../AppContext';
// TODO: move in a better place useCatalogMenu
import useCatalogMenu from '../Catalog/useCatalogMenu';

import LayoutHeader from './LayoutHeader';
import LayoutFooter from './LayoutFooter';
import useInventory from '../../services/useInventory';

import useWindowSize from '../useWindowSize';

export function LayoutView({
  lang, languages, setLang, inventory, sidebar, history, isAppLoading, children, t,
}) {
  const [goTo, setGoTo] = useState(null);
  const { menu } = useCatalogMenu({ data: inventory });

  const [, windowHeight] = useWindowSize();
  const scrollPosition = useScrollPosition();

  const { en, ru } = languages;

  return (
    <Dimmer.Dimmable dimmed={isAppLoading}>
      <SplashScreen visible={isAppLoading} />
      {!isAppLoading && (
      <>
        <Responsive maxWidth={768}>
          <Sidebar.Pushable as={Segment} style={{ margin: 0 }}>
            <Sidebar
              as={SidebarMenu}
              animation="overlay"
              direction="right"
              onHide={() => {
                document.body.style.overflow = 'scroll';
                const $goTo = goTo;
                setGoTo(null);
                if ($goTo) {
                  history.push(goTo);
                }
                sidebar.hide();
              }}
              onShow={() => {
                document.body.style.overflow = 'hidden';
              }}
              vertical
              visible={sidebar.isVisible}
              height={windowHeight}
              style={{
                top: scrollPosition, paddingTop: 80, paddingBottom: 15, textAlign: 'left',
              }}
            >
              <Menu.Item>
                <Menu.Menu className="mobileLanguageSelection">
                  <Menu.Item
                    className="mobileLanguageSelectionItem"
                    active={lang === en}
                    onClick={(e) => {
                      e.preventDefault();
                      setLang(en);
                      return false;
                    }}

                  >
                    <Flag name="gb" />
                    {en.toUpperCase()}
                  </Menu.Item>
                  <Menu.Item
                    className="mobileLanguageSelectionItem"
                    active={lang === ru}
                    onClick={(e) => {
                      e.preventDefault();
                      setLang(ru);
                      return false;
                    }}

                  >
                    <Flag name="ru" />
                    {ru.toUpperCase()}
                  </Menu.Item>
                </Menu.Menu>
              </Menu.Item>
              {Object.entries(menu).map(([header, items]) => (
                <Menu.Item key={header}>
                  {t(header)}
                  <Menu.Menu>
                    <Menu.Item
                      onClick={() => {
                        sidebar.hide();
                        setGoTo(`/catalog/${header.toLowerCase()}`);
                      }}
                    >
                      {t('all')}
                    </Menu.Item>
                    {items.map((item) => (
                      <Menu.Item
                        key={item}
                        onClick={() => {
                          sidebar.hide();
                          setGoTo(`/catalog/${header.toLowerCase()}/${item.toLowerCase()}`);
                        }}
                      >
                        {t(item)}
                      </Menu.Item>
                    ))}
                  </Menu.Menu>
                </Menu.Item>
              ))}
              <Menu.Item onClick={() => {
                sidebar.hide();
                setGoTo('/photos');
              }}
              >
                {t('layout.header.menu.main.photos')}
              </Menu.Item>
              <Menu.Item onClick={() => {
                sidebar.hide();
                setGoTo('/where-to-buy');
              }}
              >
                {t('layout.header.menu.main.where.to.buy')}
              </Menu.Item>
              <Menu.Item onClick={() => {
                sidebar.hide();
                setGoTo('/delivery-and-payment');
              }}
              >
                {t('layout.header.menu.main.delivery.and.payment')}
              </Menu.Item>
              <Menu.Item onClick={() => {
                sidebar.hide();
                setGoTo('/about');
              }}
              >
                {t('layout.header.menu.main.about')}
              </Menu.Item>
            </Sidebar>
            <Sidebar.Pusher dimmed={sidebar.isVisible} style={{ marginTop: '5.5em' }}>
              {children}
              <LayoutFooter />
            </Sidebar.Pusher>
          </Sidebar.Pushable>
          <LayoutHeader />
        </Responsive>
        <Responsive minWidth={769}>
          <LayoutHeader />
          {children}
          <LayoutFooter />
        </Responsive>
      </>
      )}
    </Dimmer.Dimmable>
  );
}


export default function Layout({ children }) {
  const { sidebar, app, locale } = getAppContext();
  const { inventory, loading } = useInventory();
  const { getLang, setLang } = locale;

  const history = useHistory();
  const { t } = useTranslation();
  const isAppLoading = app.loading || locale.loading || loading;
  return (
    <LayoutView
      languages={LANGUAGES}
      lang={getLang()}
      setLang={setLang}
      inventory={inventory}
      sidebar={sidebar}
      history={history}
      isAppLoading={isAppLoading}
      t={t}
    >
      {children}
    </LayoutView>
  );
}
