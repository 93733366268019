/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import LazyImage from './LazyImage';


export default function CloudinaryImage({
  src, alt, label, width, height, containerRef,
}) {
  const transformation = [`c_scale,w_${width}`];
  if (height) {
    transformation.push(`h_${height}`);
  }

  const lazySrc = src.replace(/upload\/v/, `upload/q_auto:low,e_blur:200,${transformation.join(',')}/v`);
  return (
    <LazyImage
      lazySrc={lazySrc}
      src={src}
      alt={alt}
      label={label}
      width={width}
      height={height}
      containerRef={containerRef}
    />
  );
}
