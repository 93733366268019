/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { Menu, Responsive } from 'semantic-ui-react';

const LocaleMenuSkeleton = styled(Menu)`
    &&&&&{
        margin-bottom: 0;
        padding: 0 1em;

        @media(max-width: ${Responsive.onlyMobile.maxWidth}px) {
            display: none;
        }
    }
`;

const LocaleMenu = ({ children }) => (
  <LocaleMenuSkeleton secondary>
    <Menu.Menu position="right">{children}</Menu.Menu>
  </LocaleMenuSkeleton>
);


LocaleMenu.Item = styled((props) => <Menu.Item {...props} as="a" href="#" />)`
    &&&&&{
        margin-left: 0px;
        margin-right: 0px;
        padding: 0.3em;
        font-size: 1.2em;
        &.active{
            background: none;
            color: rgb(255, 145, 146)
        }
    }
`;

export default LocaleMenu;
