import React from 'react';
import { LocaleMenu } from 'paperm88n-ui';
import { LANGUAGES } from '../../constants';
import { getLocale } from '../AppContext';

export function LayoutLocaleMenuView(props) {
  const { languages, lang, setLang } = props;
  const { en, ru } = languages;

  return (
    <LocaleMenu>
      <LocaleMenu.Item
        active={lang === en}
        onClick={(e) => {
          e.preventDefault();
          setLang(en);
          return false;
        }}
        content="EN"
      />
      <LocaleMenu.Item
        active={lang === ru}
        onClick={(e) => {
          e.preventDefault();
          setLang(ru);
          return false;
        }}
        content="RU"
      />
    </LocaleMenu>
  );
}

export default function LayoutLocaleMenu() {
  const { getLang, setLang } = getLocale();

  return (
    <LayoutLocaleMenuView
      languages={LANGUAGES}
      lang={getLang()}
      setLang={setLang}
    />
  );
}
