import React, { useState, useEffect } from 'react';
import { Placeholder, Image as SemanticImage } from 'semantic-ui-react';
import styled from 'styled-components';
import useIntersect from './useIntersect';


const ImageFadeInEffect = styled(SemanticImage)`
  animation: fadeIn ease 1s;
  @keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
  }
`;

export default function LazyImageView({
  lazySrc, src, alt, label, className, height, width, containerRef,
}) {
  const [isLoading, setLoading] = useState(true);

  const [intersectRef, entry] = useIntersect({
    threshold: [0, 0.25, 0.5, 0.75, 1],
  });


  useEffect(() => {
    if (isLoading === true && entry.isIntersecting) {
      setLoading(true);
      const imgLoaderLow = new Image();
      imgLoaderLow.src = src;
      imgLoaderLow.onload = () => {
        setLoading(false);
      };
    }
  }, [entry.isIntersecting, src]);

  const setBothRefs = (node) => {
    if (containerRef) {
      // eslint-disable-next-line no-param-reassign
      containerRef.current = node;
    }
    intersectRef(node);
  };

  const content = isLoading ? (
    <Placeholder fluid style={{ height, width }}>
      <Placeholder.Image rectangular />
    </Placeholder>
  ) : (
    <ImageFadeInEffect
      src={src}
      alt={alt}
      label={label}
      width={width}
      height={height}
      className={className}
    />
  );

  return (
    <span
      ref={setBothRefs}
      style={{
        backgroundImage: !isLoading ? `url(${lazySrc})` : '', height, width, display: 'block',
      }}
    >
      {content}
    </span>
  );
}
